html {
  height: 100%;
}
body {
  height: 100%;
}
#notfound {
  @font-face {
    font-family: 'Cabin Sketch';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/cabinsketch/v19/QGYpz_kZZAGCONcK2A4bGOj8mNhN.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }


  display: flex;
  min-height: 100vh;
  background-color: white;
  // body {
  // }


  h1 {
    font-family: "Cabin Sketch", cursive;
    font-size: 3em;
    text-align: center;
    opacity: 0.8;
    order: 1;
  }

  h1 small {
    display: block;
  }

  .site {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sketch {
    position: relative;
    height: 400px;
    min-width: 400px;
    margin: 0;
    overflow: visible;
    order: 2;
  }

  .bee-sketch {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .red {
    background: url(../../images/NotFound/red-1.png) no-repeat center center;
    opacity: 1;
    animation: red 3s linear infinite, opacityRed 5s linear alternate infinite;
  }

  .blue {
    background: url(../../images/NotFound/blue-1.png) no-repeat center center;
    opacity: 0;
    animation: blue 3s linear infinite, opacityBlue 5s linear alternate infinite;
  }

  @media only screen and (min-width: 780px) {
    .site {
      flex-direction: row;
      padding: 1em 3em 1em 0em;
    }

    h1 {
      text-align: right;
      order: 2;
      padding-bottom: 2em;
      padding-left: 2em;
    }

    .sketch {
      order: 1;
    }
  }

  @keyframes blue {
    0% {
      background-image: url(../../images/NotFound/blue-1.png);
    }
    9.09% {
      background-image: url(../../images/NotFound/blue-2.png);
    }
    27.27% {
      background-image: url(../../images/NotFound/blue-3.png);
    }
    36.36% {
      background-image: url(../../images/NotFound/blue-4.png);
    }
    45.45% {
      background-image: url(../../images/NotFound/blue-5.png);
    }
    54.54% {
      background-image: url(../../images/NotFound/blue-6.png);
    }
    63.63% {
      background-image: url(../../images/NotFound/blue-7.png);
    }
    72.72% {
      background-image: url(../../images/NotFound/blue-8.png);
    }
    81.81% {
      background-image: url(../../images/NotFound/blue-9.png);
    }
    100% {
      background-image: url(../../images/NotFound/blue-1.png);
    }
  }

  @keyframes red {
    0% {
      background-image: url(../../images/NotFound/red-1.png);
    }
    9.09% {
      background-image: url(../../images/NotFound/red-2.png);
    }
    27.27% {
      background-image: url(../../images/NotFound/red-3.png);
    }
    36.36% {
      background-image: url(../../images/NotFound/red-4.png);
    }
    45.45% {
      background-image: url(../../images/NotFound/red-5.png);
    }
    54.54% {
      background-image: url(../../images/NotFound/red-6.png);
    }
    63.63% {
      background-image: url(../../images/NotFound/red-7.png);
    }
    72.72% {
      background-image: url(../../images/NotFound/red-8.png);
    }
    81.81% {
      background-image: url(../../images/NotFound/red-9.png);
    }
    100% {
      background-image: url(../../images/NotFound/red-1.png);
    }
  }

  @keyframes opacityBlue {
    from {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes opacityRed {
    from {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 0.3;
    }
    to {
      opacity: 0.3;
    }
  }
}
