.App {
  height: 100vh;
  width: 100vw;
  background-image: url(../../images/Poster1.png);
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  position: relative;
  animation: fadeIn 10s forwards;
  @media only screen and (max-width: 576px) {
    & {
      background-size: 305%;
      background-repeat: round;
    }
  }
  .container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 50%;
    width: 76%;
    .glitch {
      color: rgb(223, 191, 191);
      position: relative;
      font-size: 7vw;
      animation: glitch 5s 5s infinite;
      &::before {
        content: attr(data-text);
        position: absolute;
        left: -2px;
        text-shadow: -5px 0 magenta;
        background: black;
        overflow: hidden;
        top: 0;
        animation: noise-1 3s linear infinite alternate-reverse,
          glitch 5s 5.05s infinite;
      }
      &::after {
        content: attr(data-text);
        position: absolute;
        left: 2px;
        text-shadow: -5px 0 lightgreen;
        background: black;
        overflow: hidden;
        top: 0;
        animation: noise-2 3s linear infinite alternate-reverse,
          glitch 5s 5s infinite;
      }
    }
    .scanlines {
      overflow: hidden;
      mix-blend-mode: difference;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background: repeating-linear-gradient(
          to bottom,
          transparent 0%,
          rgba(255, 255, 255, 0.05) 0.5%,
          transparent 1%
        );

        animation: fudge 7s ease-in-out alternate infinite;
      }
    }

    .glow {
      @extend .glitch;
      text-shadow: 0 0 1000px rgb(223, 191, 191);
      color: transparent;
      position: absolute;
      top: 0;
    }

    .subtitle {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 100;
      font-size: 1.5vw;
      color: rgba(165, 141, 141, 0.4);
      text-transform: uppercase;
      letter-spacing: 1em;
      text-align: center;
      position: absolute;
      left: 33%;
      animation: glitch-2 5s 5.02s infinite;
    }
  }
}

@keyframes glitch {
  1% {
    transform: rotateX(10deg) skewX(90deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes noise-1 {
  $steps: 30;
  @for $i from 1 through $steps {
    #{percentage(calc($i/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}px 0 #{$bottom}px 0);
    }
  }
}

@keyframes noise-2 {
  $steps: 30;
  @for $i from 0 through $steps {
    #{percentage($i*calc(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}px 0 #{$bottom}px 0);
    }
  }
}

@keyframes fudge {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
}

@keyframes glitch-2 {
  1% {
    transform: rotateX(10deg) skewX(70deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
